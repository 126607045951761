import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import "./Copyright.css";

export default function Copyright() {
  return (
    <div>
      <div className="copyright-content">
        <div className="footer">
          <div className="footer-left">
            <a href="#Header">
              <img
                src={require("../../assets/images/logo2.png")}
                alt="ecohodo-logo"
              />
            </a>
            <p>
              Achieve your sustainability goals faster with Ecohodo—your
              One-stop Al-Powered Carbon Emission Management Solution.
            </p>
            <div>
              <IconButton
                href="https://in.linkedin.com/company/ecohodo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon className="social-media-icon" />
              </IconButton>
              <IconButton
                href="https://twitter.com/hodoeco"
                target="_blank"
                rel="noopener noreferrer"
              >
                <XIcon className="social-media-icon" />
              </IconButton>
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-section">
              <h4>Products</h4>
              <ul>
                <li>
                  <a href="/#OurProducts">Our Products</a>
                </li>
                <li>
                  <a href="/#OurFeatures">Our Features</a>
                </li>
                <li>
                  <a href="/#OurPartners">Our Partners</a>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <h4>Company</h4>
              <ul>
                <li>
                  <a href="/#OurProjects">Our Projects</a>
                </li>
                <li>
                  <a href="/#OurTestimonials">Our Testimonials</a>
                </li>
                {/* <li><a href='#'>About Us</a></li>  */}
                {/* <li><a href='/bloglisting'>Blogs</a></li>            */}
              </ul>
            </div>
            <div className="footer-section">
              <h4>Resources</h4>
              <ul>
                <li>
                  <a href="/termsOfService">Terms of Service</a>
                </li>
                <li>
                  <a href="/privacyPolicy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/cookiePolicy">Cookie Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div className="footer-end">
          Copyright © Ecohodo {new Date().getFullYear()}. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}
